import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VoidPageProps } from '../utils/graphql';
import DefaultLayout from '../components/layouts/default-layout';

const NotFound: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('404');
  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      showContactForm={false}
    >
      <h1 className="text-reacteev-blue">{t('title')}</h1>
    </DefaultLayout>
  );
};

export default NotFound;
